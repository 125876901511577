import axios from "axios";
import moment from "moment-timezone";
import { TOOKAN_URL, TOOKAN_TOKEN } from "services/constants";

const api = axios.create({
  baseURL: TOOKAN_URL,
  timeout: 300000,// 5 minutes
});

export const createMultipleTasks = async ({group, pickupDatetime, deliveryDatetime}) =>  {
  
  try {
    const principalItem = group.orders[0];
    let pickup = {
      order_id: 'bipagem',
      name: `${principalItem?.origin_reference} - ${principalItem?.origin_name}`,
      email: principalItem?.email,
      address:  `${principalItem?.origin_street}, ${principalItem?.origin_number}, ${principalItem?.origin_additional }, ${principalItem?.origin_quarter}, ${principalItem?.origin_city}-${principalItem?.origin_state_code}, CEP: ${principalItem?.origin_zip_code}`,
      latitude: '',
      longitude: '',
      time: moment(pickupDatetime, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'), //adicionar campos (input) para digitação de data e hora
      phone: principalItem?.origin_customer_phone,
      job_description: '',
      template_name: group?.pickupTemplate?.template,
    }
    let payload = {
      api_key: TOOKAN_TOKEN,
      timezone: 180, // (GMT -3)
      has_pickup: 1,
      has_delivery: 1,
      layout_type: 0, // Set it as 0 (PICKUP_AND_DELIVERY), 1 (APPOINTMENT) or 2 (FOS) depending upon the type of layout.
      geofence: 0,
      team_id: '',
      auto_assignment: 0,
      tags: '',
      pickups: [pickup],
      deliveries: [
      ],
    };
    
    const descriptions = [];
    for await (const el of group.orders) {
      descriptions.push(`${el?.first_name} ${el?.last_name} - ${el?.order_number}`);

      const delivery = {
        order_id: el?.order_number,
        name: `${el?.first_name} - ${el?.last_name}`,
        email: el?.email,
        address:  `${el?.shipping_address}, ${el?.shipping_number}, ${el?.shipping_quarter}, ${el?.shipping_city}-${el?.shipping_state}, CEP: ${el?.shipping_zip_code}`,
        latitude: '',
        longitude: '',
        time: moment(deliveryDatetime, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'), //adicionar campos (input) para digitação de data e hora
        phone: el?.phone,
        job_description: el?.shipping_additional,
        template_name: group?.deliveryTemplate?.template,
      }
      payload.deliveries.push(delivery);
    }
    
    pickup.job_description = descriptions.join("\n");
    
    // console.log('TOOKAN_URL', `${TOOKAN_URL}/v2/create_multiple_tasks`);
    // console.log('payload', JSON.stringify(payload));
    // const response = {status:200, statusCode:200, statusText:'success'};
    
    const response = await api.post("/v2/create_multiple_tasks", payload); // {status: 200, response: {data: payload} };
    
    return response; 
  } catch (error) {
    console.log(error);
    group['message'] = error?.message;
    // throw error;
  }
}

export const createGroups = async ({provider, orders}) => {
  const { pickup_templates, delivery_templates } = provider;
  // Filtra os objetos para remover aqueles com shipment_order_type "Premium"
  const filteredData = orders.filter(item => item.shipment_order_type !== "Premium");

  // Organiza os objetos por origin_name
  const groupedData = filteredData.reduce((accumulated, item) => {
    const originName = item.origin_name;
    
    // Se o grupo para o origin_name não existir, cria um novo
    if (!accumulated[originName]) {
      accumulated[originName] = [];
    }
    
    // Adiciona o item ao grupo correspondente
    accumulated[originName].push(item);
    
    return accumulated;
  }, {});

  const getPickupTemplate = (count) => {
    return pickup_templates.find(template => count >= template.from && count <= template.to);
  };

  const getDeiveryTemplate = (count) => {
    return delivery_templates.find(template => count >= template.from && count <= template.to);
  };

  // Converte o objeto de grupos em um array
  const result = Object.entries(groupedData).map(([key, value]) => ({
    origin_name: key,
    orders: value,
    count: value.length,
    pickupTemplate: getPickupTemplate(value.length),
    deliveryTemplate: getDeiveryTemplate(value.length),
    status: 0,
    message: ''
  }));

  return result;
}